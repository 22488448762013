import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
// import { ApiHandler, NotificationService } from '../../http/user/';
import { environment } from '../../../../../environments/environment';
import { ApiHandler } from 'src/app/core/services/api.handler';
import { NotificationService } from 'src/app/core/services/notification.service';
// import { IUserModel } from 'src/app/shared/models';
import { Authenticationservice } from 'src/app/core/authentication/authenticationService';

export interface PasswordChangeContext {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    UserDetailsList: any[] = [];
    constructor(private apiHandler: ApiHandler, 
      private authService: Authenticationservice,private notificationService: NotificationService) {
        // let user = localStorage.getItem('user');
        // if (user)
        //     this.UserDetailsList = JSON.parse(user);

    }

    private USER = new BehaviorSubject(this.UserDetailsList);
    UserDetails = this.USER.asObservable();

    private accountSource = new BehaviorSubject(false);
    public getAccountStatus = this.accountSource.asObservable();

  changeUserDetail(status: any) {
    this.UserDetailsList = status;
    this.USER.next(status);
  }
  getRolePrivilege(_roleId: any, _currentModule = '') {
    return of([{ add: 1 }, { edit: 2 }, { view: 3 }, { delete: 4 }]);
  }
  changeAccountStatus(status: boolean) {
    this.accountSource.next(status);
  }
  private handleError(error: any) {
    // return Observable.throw(error); // <= B
    return of(error.error);
  }
  checkPartnerSystemForDealer(userId: any) {
    return this.apiHandler
      .get(`${environment.API_URL.DataLayer.getPartnerIntegrationStatus}` + userId + `/partner-integration-status`)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          else {
            return false;
          }
        })
      )
      .pipe(catchError(this.handleError));
  }
  GetDealerDashboard() {
    return this.apiHandler.get(`${environment.API_URL.VoiceVideo.url}`+'/api/Dealer/GetDealerDashboard')
    .pipe(
      map((response) => {
        if (response) {
          return response;
        } else {
          return null;
        }
      }))
    .pipe(catchError(this.handleError));
   
}
  checkImportValidatorUserForDealer(dealerUserID: any) {
    return this.apiHandler
      .get(`${environment.API_URL.VoiceVideo.getIsImportValidatorUserForDealer}` + dealerUserID)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          else {
            return false;
          }
        })
      )
      .pipe(catchError(this.handleError));
  }
}
