import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Authenticationservice } from '../authentication/authenticationService';
import { APP_DI_CONFIG } from 'src/app/config/app.config';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
   headersConfig:any;
   isfromportalIs_V:any=APP_DI_CONFIG.Is_E; 
  constructor(private authService: Authenticationservice,private router : Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     this.headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    const token = "Bearer " + this.authService.getToken();
    const key = this.authService.getKey();
    const isfromportal = this.isfromportalIs_V;
    if (token) {
      this.headersConfig['AuthToken'] = `${token}`;
      this.headersConfig['AuthKey'] = `${key}`;
      this.headersConfig['Is_E'] = `${isfromportal}`;
    }

    const csrfToken = this.authService.getCsrfToken();
    if (csrfToken) {
      this.headersConfig['X-CSRF-Token'] = csrfToken;
    }

    const request = req.clone({ setHeaders: this.headersConfig });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.statusText == "Unauthorized") {
          const authToken = this.authService.getToken();
          if (authToken) {
            alert("The session time has expired. Please re-login again!");
          }
          localStorage.clear();
          this.router.navigate(['/auth/login']);  
        }
        return throwError(error);
      })
    );
  }
}
