import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute , Router} from '@angular/router';
import { handleKeyPress, handlePaste, handleInput } from './security/input-handler.function';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // isLoading:false;
  title = 'cellgateweb';
  name = 'Translation';
  constructor(public translate: TranslateService,private route : ActivatedRoute, private router : Router) {

  }

  ngOnInit(): void {
    
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    handleKeyPress(event);
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    handlePaste(event);
  }

  @HostListener('document:input', ['$event'])
  onInput(event: InputEvent){
    handleInput(event);
  }
    
}
