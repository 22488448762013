import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  public routeParams: any;
  public data: any;
  public isChunckError: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.routeParams = this.activatedRoute.snapshot.queryParams;
    this.data = this.activatedRoute.snapshot.data;
    this.isChunckError = false;

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkFailedMessage2 = /ChunkLoadError [\d]+ failed/;

    if (this.routeParams !== undefined && this.routeParams !== null && (this.routeParams.name === 'TypeError' || this.routeParams.name === 'Error')) {
      this.router.navigate(['/error']);
    }
    else if (
      this.routeParams &&
      (this.routeParams.name === 'ChunkLoadError' ||
        chunkFailedMessage.test(this.routeParams.name) ||
        chunkFailedMessage2.test(this.routeParams.name) ||
        chunkFailedMessage.test(this.routeParams.message) ||
        chunkFailedMessage2.test(this.routeParams.message))
    ) {
      this.isChunckError = true;
    } else if (
      this.routeParams &&
      (this.routeParams.name === 'Network error' || this.routeParams.message.includes('Network error: Token is null'))
    ) {
      this.router.navigate(['/auth/login']);
    } else if (this.routeParams && this.routeParams.status !== undefined && this.routeParams.status === '401') {
      this.router.navigate(['/auth/login']);
    } else if (this.data && (this.data.error === 401 || this.data.error === 500)) {
      this.router.navigate(['/error']);
    }  else if (this.data && this.data.error === 404 && environment.production) {
      this.router.navigate(['/error']);
    }
    else {
      this.router.navigate(['/error']);
    }
  }
}
