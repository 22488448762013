import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LanguageTranslationModule } from './core/services/language-translation.module';
import { ApiHandler } from './core/services/api.handler';
import { NotificationService } from './core/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { MY_DECLARATIONS, UTIL_MODULES, APP_RESOLVER_PROVIDERS } from './_app.module.constant';
import { APP_CONFIG, APP_DI_CONFIG } from './config/app.config';
import { CoreModule, ErrorsModule } from './core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerCircularModule } from 'spinners-angular/spinner-circular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DataTablesModule } from 'angular-datatables';
import { sanitizeInputFactory } from './security/sanitize-input.function';

@NgModule({
  declarations:[AppComponent, [...MY_DECLARATIONS]],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    TranslateModule,
    LanguageTranslationModule,
    ErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerCircularModule,
    TooltipModule.forRoot(),
    DataTablesModule,
    [...UTIL_MODULES]
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  providers: [ ApiHandler,NotificationService,
    { provide: APP_CONFIG, useValue: APP_DI_CONFIG },
    {
      provide: APP_INITIALIZER,
      useFactory: sanitizeInputFactory,
      deps: [DomSanitizer],
      multi: true,
    },
    ...APP_RESOLVER_PROVIDERS],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
 
})
export class AppModule { 
  
}
