import env from './.env';
import { IEnv } from './Ienv';
import { APIURL } from './api.url';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const s3FolderBase = `consumer/dev/portal`;
//change the below accountkit url pointing to localhost port, if api services are running in localhost
const Accountkit =`https://accountkit-qa.zapopen.com`;
const VoiceVideo=`https://voicevideocall-qa.zapopen.com`;
const Log=`https://log-qa.zapopen.com`;
const Devtestapp = `https://devtestapp-local.azurewebsites.net`;
const CellGateConnector = `https://dev-cellgate-connector.azurewebsites.net`;
const OpenpathConnector = `https://dev-openpath-connector.azurewebsites.net`;
const DataLayer = "https://partnerdatalayer-qa.zapopen.com";
export const UserPortal=`https://user-qa.zapopen.com`;
export const environment: IEnv = {
  version: env.app_version,
  build_version: env.app_build_version,
  isVersionCheck: false,
  production: true,
  mode: 'dev',
  SITE_NAME: '',
  COPYRIGHT_TEXT: '©2020',
  DEFAULT_PAGINATION_LIMIT: 10,
  USER_PORTAL: UserPortal,
  API_URL: APIURL({
    Accountkit,
    VoiceVideo,
    Log,
    Devtestapp,
    UserPortal,
    CellGateConnector,
    OpenpathConnector,
    DataLayer,
  })
};

