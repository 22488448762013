<div class="container error-container">
  <div class="col">
    <div *ngIf="
        (data?.error && data?.error == 404) ||
        (routeParams && routeParams.status == 401) ||
        !routeParams.url ||
        routeParams.status == '404' ||
        (!routeParams && !data)
      ">
      <h5 *ngIf="!routeParams">Not found :(</h5>
      <p *ngIf="isChunckError" class="font-weight-bold">We got an update, please click go home to continue.</p>
      <a *ngIf="!isChunckError" class="btn" href="/auth/login">
        <h5>Go Home</h5>
      </a>
      <a *ngIf="isChunckError" class="btn" href="/">
        <h5><button class="btn btn-primary">Go Home</button></h5>
      </a>
    </div>
  </div>
</div>
<div class="pre-container" *ngIf="!isChunckError && (routeParams?.name=='TypeError' || routeParams?.name=='Error')">
  <p>Error occurred while processing the request</p>
</div>
