import { Injectable, NgModule } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, NavigationStart, Route, Router, RouterModule, RouterStateSnapshot, Routes, UrlSegment, UrlTree } from '@angular/router';
import { CommonModule } from '@angular/common';  ;  
import { LoginComponent } from './modules/auth//login/login.component';  
import { LanguageTranslationModule } from './core/services/language-translation.module';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorsComponent } from './core/errors';
import { Observable,of } from 'rxjs';
import { Authenticationservice } from './core/authentication/authenticationService';
import { UserService } from './shared/services/http';

@Injectable()
export class UserLoad implements CanLoad {
  constructor(private userService: UserService, private authService: Authenticationservice){}
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.userService.UserDetailsList)
      return of(true);
    return this.authService.getUserDetailsModule();
  }
}
@Injectable()
export class SiteGuard implements CanActivate {
  constructor( private authService: Authenticationservice, private router:Router){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.authService.isAuthenticated())
    {
        return true;
    }   
      else{
        return this.router.parseUrl('/auth');
      }
   
  }
}
const routes: Routes = [{  
  path: '',  
  redirectTo: 'auth',  
  pathMatch: 'full'  
},{  
  path: 'auth',
  loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
},
{
  path: 'site',
  loadChildren: () => import('./modules/site/site.module').then((m) => m.SiteModule),
  canActivate:[SiteGuard]
},
// {
//   path: 'server-error',
//   loadChildren: () => import('./layout/server-error/server-error.module').then((m) => m.ServerErrorModule)
// },
// {
//   path: 'access-denied',
//   loadChildren: () => import('./layout/access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
// },
// {
//   path: 'not-found',
//   loadChildren: () => import('./layout/not-found/not-found.module').then((m) => m.NotFoundModule)
// },
{ path: 'error', component: ErrorsComponent, data: { error: 404 } },
{ path: '**', redirectTo:'auth' }
] 
@NgModule({
 //  declarations: [LoginComponent],  
  imports: [ CommonModule, RouterModule.forRoot(routes),LanguageTranslationModule,TranslateModule],
  providers:[SiteGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  static components = [];
}
