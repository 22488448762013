import { Injectable } from '@angular/core';
import { AuthenticationModel } from './authentication.model';
// import { AuthenticationModel } from "./authentication.model";
// import { NotificationService } from "../services";

export interface Credentials {
  // Customize received credentials here
  authToken: string;
  authKey:string;
  refreshToken?: string;
  user?: AuthenticationModel;
}

const credentialsKey = 'credentials';
const userkey = 'user';

@Injectable({
  providedIn: 'root'
})
export class AdminLocalStorageService {
  public _credentials: {} | any;

  constructor() {}

  public setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  public setUser(details: any = null) {
    if (details) {
      const storage = localStorage;
      storage.setItem(userkey, JSON.stringify(details));
    } else {
      sessionStorage.removeItem(userkey);
      localStorage.removeItem(userkey);
    }
  }

  public getCredentials() {
    return sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
  }
  public getSearchCredentials() {
    return sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
  }
  public getCurrentUser() {
    const savedCredentials = this.getCredentials();

    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authKey'] : null;
  }

  public getToken(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authToken'] : null;
  }

  public getRole(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authuRole'] : null;
  }
  public getKey(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['authKey'] : null;
  }
  public getCsrfToken(): string | null {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['csrf'] : null;
  }
  public getCurrentUserPermittedModules() {
    const user = this.getCurrentUser();

    return user ? user.modules : [];
  }

  public getSidebarMinimized() {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials ? this._credentials['sidebar_minimize'] : null;
  }

  public getSavedLang() {
    const savedCredentials = this.getCredentials();
    this._credentials = savedCredentials ? JSON.parse(savedCredentials) : null;
    return this._credentials && this._credentials['lang'] ? this._credentials['lang'] : 'en';
  }
}
