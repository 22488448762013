const notallowedCharacters = /^[<>=]*$/;
const maliciousPatterns =
  /(https?:|ftp:|file:|http:|mailto:|data:|javascript:|vbscript:|=:|<[^>]*>|=HYPERLINK\([^)]*\)|\.(exe|bat|cmd|js|vbs|html|php))/i;

export function handleKeyPress(event: KeyboardEvent) {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  if (
    target &&
    (target.type === 'text' || target.type === 'textarea') &&
    !isEmailInput(target)
  ) {
    const key = event.key;
    if (notallowedCharacters.test(key) || maliciousPatterns.test(key)) {
      event.preventDefault();
    }
  }
}

export function handlePaste(event: ClipboardEvent) {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  if (
    target &&
    (target.type === 'text' || target.type === 'textarea') &&
    !isEmailInput(target)
  ) {
    const clipboardData = event.clipboardData || (window as any)?.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (
      notallowedCharacters.test(pastedText) ||
      maliciousPatterns.test(pastedText)
    ) {
      event.preventDefault();
    }
  }
}

export function handleInput(event: InputEvent) {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  if (
    target &&
    (target.type === 'text' || target.type === 'textarea') &&
    !isEmailInput(target)
  ) {
    const value = target.value;
    if (maliciousPatterns.test(value)) {
      target.value = '';
    }
  }
}

export function isEmailInput(
  target: HTMLInputElement | HTMLTextAreaElement
): boolean {
  return (
    target.classList.contains('email') ||
    target.getAttribute('pattern') === 'EmailPattern' ||
    target.hasAttribute('email')
  );
}
